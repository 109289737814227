<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover">
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="dash_card_body pad-lr-0 ov-h">
              <div class="header_section padd-30 pt-0">
                <div class="w-100 t-wrap">
                  <h4 class="menu_header">Create Loyalty Customer.</h4>
                </div>
              </div>
              <hr>
              <form @submit.prevent="createCustomer()">
                <div class="row py-3">
                  <div class="col-md-12" v-if="errors ">
                    <div class="alert alert-danger">
                      <ul>
                        <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
    
                <div class="row">
                      
                  <div class="col-lg-6 pad-lr-0">
                    <div class="pl-5 pr-4">
                    
                      <div class="setting_content">
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Name:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="name">
                              <div class="input_error" v-if="v$.name.$error">Name is required.</div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Email:</label>
                            <div class="col-sm-7">
                              <input type="email" class="form-control" v-model="email">
                              <div class="input_error" v-if="v$.email.$error">Email is required.</div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Phone:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="phone">
                              <div class="input_error" v-if="v$.phone.$error">Phone is required.</div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="email"> Country Code:</label>
                            <div class="col-sm-7">
                              <select class="form-control" id="" v-model="countryCode"  @blur="v$.countryCode.$touch">
                                <option value="KE">KE</option>
                                <option value="TZ">TZ</option>
                                <option value="UG">UG</option>
                              </select>
                              <div class="input_error" v-if="v$.countryCode.$error">Country Code is required.</div>
                            </div>
                          </div>
                        </div>
                    
                      <!--  -->
                        
                          
                      </div>
                    </div>
    
                  </div>
                  <!--  -->
                </div>
                <div class="row bt-b">
                  <div class="col-md-12">
                    <div class="form-group ml-2r mt-3">
                      <button type="submit"  class="btn btn-orange"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save Changes</span></button>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>
    
    <script>
    // import Multiselect from '@vueform/multiselect';
    import useVuelidate from '@vuelidate/core';
    import { useToast } from "vue-toastification";
    import { required } from '@vuelidate/validators';
    import ReusableDataMixins from '@/mixins/ReusableDataMixins';
    const toast = useToast();
    import "tinymce/tinymce";
    import "tinymce/themes/silver";
    import "tinymce/icons/default";
    import "tinymce/skins/ui/oxide/skin.css";
    // import Editor from '@tinymce/tinymce-vue';
    export default {
       components: {
            // Editor
            // Multiselect
        },
        setup() {
              return { v$: useVuelidate() }
        },
        data() {
            return {
                options: [],
                name: '',
                phone: '',
                countryCode: '',
                email: '',
                errors: false,
                errorMsg: [],
                spinner: false,
                searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes..."
    
            }
        },
        mounted() {
            // this.getItems();
        },
        validations() {
      return {
          name: { required },
          phone: { required },
          countryCode: { required },
          email: { required },
          }
      },
        methods: {
          async createCustomer() {
              this.spinner = true;
              this.errors = false;
              const isFormCorrect = await this.v$.$validate()
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (!isFormCorrect) {
                    this.spinner = null;
                    return;
                }
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = `${this.dashBaseUrl}/create-loyalty-customer`;
              const payload = {
                  'name': this.name,
                  'email': this.email,
                  'user_id': '',
                  'phone': this.phone,
                  'country_code': this.countryCode,
                  'merchant_id': this.merchantID,
              }
              this.axios.post(url,payload,config).then((response)=>{
                  if(response.status ===201){
                      toast.success('Customer Created successfully');
                      this.$router.push({
                        name: 'loyalty_customers'
                      });
                  }
              }).catch((error)=>{
                  if (error.response.status === 400) {
                      this.errors = true;
                      this.errorMsg = error.response.data.errors
                  }
                  if (error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              }).finally(()=> this.spinner = false);
  
          }
        },
        mixins: [ReusableDataMixins]
    }
    </script>
  
  